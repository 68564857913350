<template>
  <b-modal
    visible
    scrollable
    centered
    title="Удаление устройств пользователя"
    @hidden="onClose"
    @ok="onDelete"
  >
    <template>
      Вы уверены?
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onDelete"
      >
        Удалить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'PatientDeviceDeleteModal',
  props: {
    personId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    onDelete() {
      this.$emit('deleteDevice');
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
